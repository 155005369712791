<template>
  <div>
    <section>
      <b-card
        no-body
        class="calibration-note"
        :style="{
          backgroundImage: `url(${require('@/assets/images/saitco-img/banner.png')})`,
        }"
      >
        <b-card-body>
          <h2 class="text-primary">{{ $t("g.note") }} :</h2>
          <b-card-text class="mb-2">
            {{ $t("g.calibrationAlertBody") }}
          </b-card-text>
        </b-card-body>
      </b-card>
    </section>
  </div>
</template>

<script>
import { BAlert, BCard, BCardBody, BCardText } from "bootstrap-vue";
import { heightFade } from "@core/directives/animations";

export default {
  components: { BAlert, BCard, BCardBody, BCardText },
  directives: {
    heightFade,
  },
};
</script>
