<template>
  <div>
    <loading v-if="isLoading" />
    <error v-else-if="isError" />
    <template v-else>
      <calibration-alert />
      <b-card>
        <div class="custom-search d-flex justify-content-between mb-1">
          <!-- search input -->
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">{{ $t("g.searchLabel") }}</label>
              <b-form-input
                v-model="searchTerm"
                :placeholder="$t('g.searchHere')"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
            <div>
              <router-link
                :to="{ name: 'AddEquipmentCalibrationPlan' }"
                class="btn btn-primary d-block ml-auto"
                style="width: fit-content"
              >
                {{ $t("g.add_calibration_reports") }}
              </router-link>
            </div>
        </div>

        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          styleClass="vgt-table condensed"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
        >
          <template slot="table-column" slot-scope="props">
            <!-- Column: Name -->
            <span v-if="props.column.label === 'name'" class="text-nowrap">
              {{ $t("g.name") }}
            </span>
            <span
              v-else-if="props.column.label === 'description'"
              class="text-nowrap"
            >
              {{ $t("g.description") }}
            </span>
            <span
              v-else-if="props.column.label === 'file_type'"
              class="text-nowrap"
            >
              {{ $t("g.file_type") }}
            </span>
            <span v-else-if="props.column.label === 'file'" class="text-nowrap">
              {{ $t("g.file") }}
            </span>

            <span v-else-if="props.column.label === 'Date'" class="text-nowrap">
              {{ $t("g.date") }}
            </span>

            <span
              v-else-if="props.column.label === 'action'"
              class="text-nowrap"
            >
              {{ $t("g.action") }}
            </span>

            <span v-else>
              {{ props.column.label }}
            </span>
          </template>

          <!-- Slot: Table Row -->
          <template slot="table-row" slot-scope="props">
            <!-- Column: Action -->
            <span v-if="props.column.field === 'file_type'" class="text-nowrap">
              <feather-icon
                size="25"
                :icon="
                  props.row.type.includes('image') ? 'ImageIcon' : 'FileIcon'
                "
              />
            </span>
            <span
              v-if="props.column.field === 'created_at_row'"
              class="text-nowrap"
            >
              {{ props.row.created_at ? format(props.row.created_at) : "-" }}
            </span>
            <span v-if="props.column.field === 'action'">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item :href="props.row.path" target="_blank">
                    <feather-icon icon="EyeIcon" class="mr-50" />
                    <span>{{ $t("g.preview") }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  {{ $t("g.pagelength") }}
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3', '5', '10']"
                  class="mx-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap">
                  {{ $t("g.of") }} {{ props.total }}
                  {{ $t("g.pageText") }}
                </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template> </vue-good-table
      ></b-card>
    </template>
  </div>
</template>

<script>
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BCard,
  BButton,
  BDropdownItem,
  BDropdown,
  VBTooltip,
  BBadge,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";
import CalibrationAlert from "./calibrationAlert.vue";
import Loading from "@/views/components/logic/loading.vue";
import Error from "@/views/components/logic/error.vue";
import formatDate from "@/libs/format-date";

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BCard,
    BButton,
    BDropdownItem,
    BDropdown,
    CalibrationAlert,
    Loading,
    Error,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      log: [],
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: "name",
          field: "name",
        },
        {
          label: "description",
          field: "description",
        },
        {
          label: "Date",
          field: "created_at_row",
        },
        {
          label: "file_type",
          field: "file_type",
        },
        {
          label: "action",
          field: "action",
        },
      ],
      rows: [],
      searchTerm: "",
      isLoading: true,
      isError: false,
      format: null,
    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  created() {
    this.format = formatDate;
    this.currentId = this.$route.params.id;
    this.$http
      .get(`admin/devices/${this.currentId}`)
      .then((res) => {
        
        this.rows = res.data.data.calibration_reports;
        this.isLoading = false;
        this.isError = false;
      })
      .catch((err) => {
        this.isLoading = false;
        this.isError = true;
        console.log(err);
      });
  },
  methods: {

    deleteAlert() {
      this.$swal({
        title: this.$t("g.areYouSure?"),
        text: this.$t("g.youWontBeAbleToRevertThis"), // You won't be able to revert this!
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("g.yesDeleteIt"), // "Yes, delete it!" ,
        cancelButtonText: this.$t("g.cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$swal({
            icon: "success",
            title: this.$t("g.deleted"), //"Deleted!"
            text: this.$t("g.yourFileHasBeenDeleted"), //"Your file has been deleted."
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
